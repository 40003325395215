import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/booking/app/[lang]/ClientLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["hCaptchaInstance","getConfig","execute"] */ "/app/packages/booking/app/modules/common/common/utils/hcaptcha-utils.ts");
;
import(/* webpackMode: "eager", webpackExports: ["getLangLocale"] */ "/app/packages/booking/app/modules/common/common/utils/i18n-utils.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default","dispatch","getState"] */ "/app/packages/booking/app/store/index.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/build/index.js");
